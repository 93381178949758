import styled from "styled-components";

export const Img = styled.img`
  width: 28px;
  height: 28px;
  margin: 0;
  border: ${({ imgborder }) =>
    imgborder ? '5px solid' : '0px'} solid ${({ lightBorder }) => (lightBorder ? '#FFFFFF' : '#000000')};
  will-change: transform, opacity;
`;

export const CustomFooter = styled.footer `
    background-color: #000000;
    height: auto;
    padding: 10px;
    border-top: 1px solid black;
`;

export const FooterP = styled.p`
    color: white;
    text-align: center;
    font-size: 14px;
`;

export const Socials = styled.ul`
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  width: 20%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  list-style-type: none;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const SocialLinks = styled.svg `
    &:hover {
        fill: white;
    }
`;