import styled, { keyframes } from 'styled-components';

// --------------------
// KEYFRAME ANIMATIONS
// --------------------

// Animate the underline’s width from 0 to 100%
const underlineSlideIn = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

// Pulse animation: moves a gradient element from left to right
const pulseAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

// Letters slide upward into view
const slideInLetter = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Enhanced neon glow pulse for the text letters with 1vw shadow separation
const letterGlowPulse = keyframes`
  90% {
    text-shadow: 0vw 0 3vw #00ffff, 1vw 0 1vw #66ffff, 2vw 0 1vw #99ffff, 3vw 0 1vw #ffffff
  }
  0% {
    text-shadow: 20vw 0 5vw #00ffff, 40vw 0 5vw #66ffff, 60vw 0 5vw #99ffff, 80vw 0 5vw #ffffff
  }
  100% {
    text-shadow: 0vw 0 3vw #000000, 1vw 0 1vw #000000, 2vw 0 1vw #000000, 3vw 0 1vw #000000
  }
`;

// Constant hue shift for subtle color variation
const colorFluctuation = keyframes`
  0% { filter: hue-rotate(0deg); }
  50% { filter: hue-rotate(20deg); }
  100% { filter: hue-rotate(0deg); }
`;

// --------------------
// STYLED COMPONENTS
// --------------------

// Outer hero container
export const HeroOuter = styled.section`
  position: relative;
  height: 100vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0% 0% 5% 5%;
  overflow: hidden;
  z-index: 0;
`;

// Text container with responsive font-size so it always fits the screen
export const AnimatedTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  font-size: clamp(4rem, 10vw, 25rem);
  letter-spacing: 5vw;
  margin: 0;
  white-space: nowrap;
`;

// Each letter with an enhanced neon glow effect
export const Letter = styled.span`
  display: inline-block;
  position: relative;
  opacity: 0;
  color: black;
  animation: ${slideInLetter} 0.5s forwards;
  animation-delay: ${({ delay }) => delay}s;
  
  
  &::before {
    content: attr(data-letter);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    color: transparent;
    -webkit-text-stroke: 0.3vw #00ffff;
    animation: ${letterGlowPulse} 5s ease-in-out forwards, ${colorFluctuation} 4s ease-in-out infinite;
  }
`;

// Underline container that wraps both the underline and its glow
// Constrained to 30px height and prevented from flex-stretching.
export const UnderlineContainer = styled.div`
margin: 0% 0% 0% -5%;
  position: relative;
  width: 100vw;
  height: 1.5vw;
  flex-shrink: 0;
`;

// The glow element: a duplicate of the moving gradient, blurred for a neon glow
export const UnderlineGlow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #00ffff, transparent);
  filter: blur(1vw);
  animation: ${underlineSlideIn} 1s ease-out forwards, ${pulseAnimation} 5s linear infinite;
  z-index: 1;
`;

// The actual underline with a white base and an animated neon blue pulse
export const Underline = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  animation: ${underlineSlideIn} 1s ease-out forwards;
  overflow: hidden;
  z-index: 2;
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%; /* extra width for a smooth transition */
    height: 100%;
    background: linear-gradient(90deg, transparent, #00ffff, transparent);
    animation: ${pulseAnimation} 5s linear infinite;
    z-index: 3;
  }
`;
