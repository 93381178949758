import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Button } from '../ButtonElement';
import {
  InfoContainer,
  OverlayWrapper,
  TextOverlay,
  LogoOverlay,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Img,
  A,
} from './InfoElements';

import {UnderlineContainer, 
  UnderlineGlow, 
  Underline 
} from './InfoElements';

gsap.registerPlugin(ScrollTrigger);

const InfoSection = () => {
  // Embedded content data
  const lightBg = false;
  const id = 'services';
  const topLine = 'Our Services';
  const lightText = true;
  const lightTextDesc = true;
  const headline = 'Empowering Your Vision';
  const description =
    "Unlock the potential of tomorrow with Exonix's comprehensive suite of cutting-edge services. We empower your unique vision.";
  const buttonLabel = ''; // No button in this case
  const button_link = '';
  // Ensure your logo image is transparent white on a black background.
  const img = require('../../images/logo_white.png');
  const alt = 'Logo';
  const lightBorder = true;
  const imgborder = false;

  // Refs for animation
  const containerRef = useRef(null);
  const logoRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    // Set initial state:
    // - Text starts hidden and slightly lowered.
    // - Logo starts off-screen at the bottom.
    gsap.set(textRef.current, { autoAlpha: 0, y: 0 });
    gsap.set(logoRef.current, { y: '100vh', scale: 1, autoAlpha: 1 });
    
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top 80%',
      },
    });
    
    // Phase 1: Animate logo rising from bottom to center.
    tl.to(logoRef.current, {
      y: 0,
      duration: 1.5,
      ease: 'power2.out',
    })
    // Phase 2: Slowly zoom in the logo (magnify it) so it covers the screen,
    // then fade it out. (The black centre makes it "disappear".)
    .to(logoRef.current, {
      scale: 100,
      autoAlpha: 0,
      duration: 3,
      ease: "expoScale(0.5,7,none)",
    }, '+=0.5')
    // Phase 3: Fade in and slide up the text.
    .to(textRef.current, {
      autoAlpha: 1,
      y: 0,
      duration: 0.5,
      ease: "power4.out",
    }, '-=2.8');
    
  }, []);

  return (
    <InfoContainer ref={containerRef} lightBg={lightBg} id={id}>
      <OverlayWrapper>
        <TextOverlay ref={textRef}>
          <TopLine>AI-Driven Software Solutions</TopLine>
          
          <div style={{width: 'fit-content'}}>
          <Heading lightText={lightText}>Transform Your Business Today</Heading>
          <UnderlineContainer>
                <UnderlineGlow />
                <Underline />
            </UnderlineContainer>
        </div>
        <br></br>
          <Subtitle lightTextDesc={lightTextDesc}><strong>Based in Brisbane, Australia, we specialize in developing custom software solutions that streamline reporting and business processes, leveraging AI for tailored applications. <br></br>Our team brings extensive experience in the civil, electrical engineering, and medical sectors.</strong></Subtitle>
          <Subtitle lightTextDesc={lightTextDesc}>
          <ul>
            <li><strong >Custom Reporting Software</strong> <br></br><p>&nbsp; Developing tools that enhance data analysis and decision-making.</p></li>
            <br></br><li><strong >Business Process Automation</strong> <br></br><p>&nbsp; Implementing solutions that save time and increase efficiency.</p></li>
            <br></br><li><strong >AI-Driven Applications</strong> <br></br><p>&nbsp; Creating bespoke AI integrations for specialized industry needs.</p></li>
            </ul>
            <br></br>
            <div style={{width: 'fit-content'}}>
            
            <UnderlineContainer>
                <UnderlineGlow />
                <Underline />
            </UnderlineContainer>
            <p>Partner with us to harness technology that propels your vision forward.</p>
            </div>
            </Subtitle>
          {buttonLabel !== '' && (
            <BtnWrap>
              <A href={button_link}>
                <Button
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  primary={0}
                  dark={0}
                >
                  {buttonLabel}
                </Button>
              </A>
            </BtnWrap>
          )}
        </TextOverlay>
        <LogoOverlay ref={logoRef}>
          <Img
            src={img}
            alt={alt}
            lightBorder={lightBorder}
            imgborder={imgborder}
          />
        </LogoOverlay>
      </OverlayWrapper>
    </InfoContainer>
  );
};

export default InfoSection;
