import styled, { keyframes } from 'styled-components';

// Animate the underline’s width from 0 to 100%
const underlineSlideIn = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

// Pulse animation: moves a gradient element from left to right
const pulseAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

export const InfoContainer = styled.div`
  position: relative;
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? '#FFFFFF' : '#000000')};
  /* Always occupy at least the full viewport height */
  min-height: 100vh;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const OverlayWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

/* TextOverlay holds the text content. It's absolutely positioned
   so that it fills the container and is revealed only after the logo fades. */
export const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

export const LogoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopLine = styled.p`
  color: rgb(0, 255, 255);
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
  
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
  }
`;

export const Heading = styled.h1`
  padding-bottom: 1.5rem;
  font-size: 3.5rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#FFFFFF' : '#000000')};
  
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.p`
  padding-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ lightTextDesc }) => (lightTextDesc ? '#FFFFFF' : '#000000')};
  
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Img = styled.img`
  width: 100%;
  max-width: 400px;
  margin: 0;
  border: ${({ imgborder }) =>
    imgborder ? '5px solid' : '0px'} solid ${({ lightBorder }) => (lightBorder ? '#FFFFFF' : '#000000')};
  will-change: transform, opacity;
`;

export const A = styled.a`
  text-decoration: none;
`;

// Underline container that wraps both the underline and its glow
// Constrained to 30px height and prevented from flex-stretching.
export const UnderlineContainer = styled.div`
  margin: 0.2em 0 1em 0%;
  position: relative;
  width: 100%;
  height: 0.25vw;
  flex-shrink: 0; 
`;

// The glow element: a duplicate of the moving gradient, blurred for a neon glow
export const UnderlineGlow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #000, transparent);
  filter: blur(1vw);
  animation: ${underlineSlideIn} 1s ease-out forwards, ${pulseAnimation} 5s linear infinite;
  z-index: 1;
`;

// The actual underline with a white base and an animated neon blue pulse
export const Underline = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00ffff;
  animation: ${underlineSlideIn} 1s ease-out forwards;
  overflow: hidden;
  z-index: 2;
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%; /* extra width for a smooth transition */
    height: 100%;
    background: linear-gradient(30deg, transparent, #FFF, transparent);
    animation: ${pulseAnimation} 3s linear infinite;
    z-index: 3;
  }
`;
