import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { 
  HeroOuter, 
  AnimatedTextContainer, 
  Letter, 
  UnderlineContainer, 
  UnderlineGlow, 
  Underline 
} from './HeroElements';

gsap.registerPlugin(ScrollToPlugin);

const HeroSection = () => {
  const heroRef = useRef(null);
  const text = "EXONIX";

  useEffect(() => {
    const heroEl = heroRef.current;
    let triggered = false;

    // This handler triggers on the very first scroll/touch
    const handleScrollStart = () => {
      if (triggered) return;
      triggered = true;
          gsap.to(window, {
            duration: 1.5,
            scrollTo: { y: heroEl.offsetHeight },
            ease: "slow(0.7,0.7,false)"
          });
    };

    // Listen for wheel events (for desktop) and touchstart (for mobile)
    window.addEventListener("wheel", handleScrollStart, { passive: true });
    window.addEventListener("touchstart", handleScrollStart, { passive: true });

    return () => {
      window.removeEventListener("wheel", handleScrollStart);
      window.removeEventListener("touchstart", handleScrollStart);
    };
  }, []);

  return (
    <HeroOuter ref={heroRef}>
      <AnimatedTextContainer>
        {text.split("").map((char, index) => (
          <Letter key={index} data-letter={char} delay={1 + index * 0.1}>
            {char}
          </Letter>
        ))}
      </AnimatedTextContainer>
      <UnderlineContainer>
        <UnderlineGlow />
        <Underline />
      </UnderlineContainer>
    </HeroOuter>
  );
};

export default HeroSection;
