import './App.css';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import Home from './pages';

import { AuthContextProvider } from './context/AuthContext';

import ReactGA from 'react-ga';
import { useEffect } from 'react';


const ANALYTICS_ID = 'G-JP5Y51E4TT' // OUR GOOGLE ANALYTICS ID (fintech :skull emoji:)
ReactGA.initialize(ANALYTICS_ID)

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
    <Router>
      <AuthContextProvider>
      <Routes>
        <Route path='/' element={<Home />} exact />
      </Routes>
      </AuthContextProvider>
    </Router>
    </>
  );
}

export default App;
